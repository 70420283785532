export const ru = {
    pick_a_station: 'Выберите станцию',
    view_stations: 'Просмотр станций',
    search_a_station: 'Поиск',
    recent_tracks: 'История',
    nextTrack: 'Следующий',
    choose_animation: 'Выберите фон',
    disable: 'Отключено',
    cloud: 'Облака',
    waves: 'Волны',
    halo: 'HALO',
    trunk: 'TRUNK',
    tw_listening_to : 'Entrain d\'écouter ',
    live_on : 'ПРЯМОЙ ЭФИР '
}
